import { EnvironmentModel } from '@app/OLD_shared/models/environment.model';
import { FeatureFlag } from '@shared/models/feature-config.model';

export const environment: EnvironmentModel = {
  production: false,
  urls: { apiUrl: 'https://api.dashly-dev.com' },
  sentryDSN: 'https://76881b87ff014000bde9e53bd7994c0a@sentry.io/1470751',
  googleCloudPlatformApiKey: 'AIzaSyBGqH3THZJ5ihSEpuXeBcEHxwe3GeyVk1k',
  signUpUrl: 'https://welcome.dashly-dev.com',
  featureConfig: {
    [FeatureFlag.LANDCLAN_ADDRESS]: false,
    [FeatureFlag.CREDIT_REPORT_SETTINGS]: true,
    [FeatureFlag.ADD_SMART_SEARCH_FORM]: true,
  },
};
